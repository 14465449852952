/**
 * Created By Hensi Murcia
 */
"use strict";

import {
  setDisplay,
  openCommonModal,
  setModalMessage,
  showToast,
  closeMyModal,
  setCookie,
  getCookie,
} from "./common";

//Initialize elements
var btnLogin = document.getElementById("btnLogin");
var etEmail = document.getElementById("etEmail");
var etPassword = document.getElementById("etPassword");
var loader = document.getElementById("loading");
var btnPhoneLogin = document.getElementById("btnPhoneLogin");
var btnEmailLogin = document.getElementById("btnEmailLogin");
var divEmailOptions = document.getElementById("emailOptions");
var divPhoneOptions = document.getElementById("phoneOptions");
var ui = new firebaseui.auth.AuthUI(firebase.auth());
var trans;

$(document).ready(function () {
  $(function () {
    translatePage();
    initClickListeners();
    initFirebaseUi();

    window.onclick = function (event) {
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    };
    btnLogin.addEventListener("click", login);
    etPassword.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        //"Enter" key on keyboard
        //cancel the deafult button if needed
        event.preventDefault();
        btnLogin.click();
      }
    });

    btnNext.addEventListener("click", function () {
      checkEmail(etEmail.value);
    });
    etEmail.addEventListener("keyup", function (event) {
      if (event.keyCode === 13) {
        //cancel the deafult button if needed
        event.preventDefault();
        btnNext.click();
        etPassword.focus();
      }
    });
    btnRegister.addEventListener("click", function () {
      var email = etEmail.value.trim();
      var pass = etPassword.value.trim();
      if (pass && email) {
        setDisplay(loading, "block");
        firebase
          .auth()
          .createUserWithEmailAndPassword(email, pass)
          .then(function (user) {
            if (user) {
              setDisplay(loading, "none");
              //getProfileInfo();
            } else {
              showToast(trans.errorRegister);
              setDisplay(loading, "none");
            }
          })
          .catch(function (error) {
            // Handle Errors here.
            var errorMessage = error.message;
            showToast(errorMessage);
            setDisplay(loading, "none");
          });
      }
    });
    btnEmailLogin.addEventListener(
      "click",
      function () {
        setDisplay(divEmailOptions, "block");
        setDisplay(divPhoneOptions, "none");
      },
      false
    );

    btnPhoneLogin.addEventListener(
      "click",
      function () {
        setDisplay(divEmailOptions, "none");
        setDisplay(divPhoneOptions, "block");
      },
      false
    );
  });
});

function initClickListeners() {
  btnTranslate.addEventListener("click", function () {
    showDropdown();
  });
  aEn.addEventListener("click", function () {
    translatePage("en");
  });
  aEs.addEventListener("click", function () {
    translatePage("es");
  });

  myModal.querySelector(".close").addEventListener("click", function () {
    closeMyModal();
  });
  myModal.querySelector(".btnBlue").addEventListener("click", function () {
    closeMyModal();
  });
}

function showDropdown() {
  document.getElementById("langDropdown").classList.toggle("show");
}

/**
 * Init Firebase phone ui
 */

function initFirebaseUi() {
  ui.start(divPhoneOptions, {
    signInOptions: [
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        recaptchaParameters: {
          type: "image", // 'audio'
          size: "normal", // 'invisible' or 'compact'
          badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
        },
        //loginHint: '+11234567890'
      },
    ],
  });
}

/**
 * check if user logged in
 */
firebase.auth().onAuthStateChanged(function (user) {
  if (user) {
    // User is signed in.
    toggleLoader(true);
    if (window.location.search.includes("action=manage")) {
      window.location.replace("../removeAccount.html?action=success");
    } else {
      window.location.replace("../chat.html");
    }
  }
});

/**
 * function login()
 * logs in user to firebase
 */
function login() {
  toggleLoader(false);
  var email = etEmail.value;
  var password = etPassword.value;

  if (!document.getElementById("cbRememberMe").checked) {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.SESSION)
      .then(function () {
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        return firebase.auth().signInWithEmailAndPassword(email, password);
      })
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        toggleLoader(true);
        setModalMessage("Error " + errorCode + ": " + errorMessage);
        openCommonModal();
      });
  } else {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;

        toggleLoader(true);
        setModalMessage("Error " + errorCode + ": " + errorMessage);
        openCommonModal();
        //window.alert('Error ' + errorCode + ": " + errorMessage);
      });
  }
}

function toggleLoader(hide) {
  if (hide) {
    loader.style.display = "none";
  } else {
    loader.style.display = "block";
  }
}

function checkEmail(email) {
  firebase
    .auth()
    .fetchSignInMethodsForEmail(email)
    .then(function (signInMethods) {
      setDisplay(btnNext, "none");
      setDisplay(etPassword, "block");
      if (
        signInMethods.indexOf(
          firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
        ) != -1
      ) {
        setDisplay(divPassInfo, "block");
        divPassInfo.textContent = trans.welcomeBack;
        etPassword.placeholder = trans.password;
        setDisplay(btnLogin, "block");
        setDisplay(btnRegister, "none");
        var checkMark = emailOptions.querySelector(".check");
        setDisplay(checkMark, "block");
        // User can sign in with email/password.
      } else {
        setDisplay(divPassInfo, "block");
        divPassInfo.textContent = trans.createPass;
        etPassword.placeholder = trans.anyPass;
        setDisplay(btnLogin, "none");
        setDisplay(btnRegister, "block");
        var checkMark = emailOptions.querySelector(".check");
        setDisplay(checkMark, "none");
      }
      setDisplay(loading, "none");
      etPassword.focus();
    })
    .catch(function (error) {
      showToast(error);
      setDisplay(loading, "none");
      // Some error occurred, you can inspect the code: error.code
    });
}

var language = {
  eng: {
    welcome: "Welcome",
    messageLogout: "Are you sure you want to logout?",
    initSession: "Login using the easiest option for you",
    phone: "Phone",
    email: "email",
    password: "Password",
    remember: "Remember me",
    login: "Login",
    disclaimer:
      'By continuing, you agree to our <a href="about.html#terms">Terms and Conditions</a>, and <a href="about.html">Privacy policy</a>',
    welcomeBack: "Welcome back! Enter the password for this account",
    anyPass: "Enter any password",
    idiom: "En",
  },
  es: {
    welcome: "Bienvenid@",
    messageLogout: "¿Estás segur@ de que quieres cerrar sesión?",
    initSession: "Inicia sesión con la opción más fácil para ti",
    phone: "Teléfono",
    email: "Correo electrónico",
    password: "Contraseña",
    remember: "Recuérdame",
    login: "Iniciar sesión",
    disclaimer:
      'Al continuar, aceptas nuestros <a href="about.html#terms">Términos y Condiciones</a>, y <a href="about.html">Política de Seguridad</a>',
    welcomeBack: "¡Bienvenido de nuevo! Ingrese la contraseña para esta cuenta",
    anyPass: "Cualquier contraseña",
    idiom: "Es",
  },
};

function translatePage(lang) {
  var translate = false;
  if (lang) {
    if (lang.includes("es")) {
      trans = language.es;
      document.getElementsByTagName("html")[0].setAttribute("lang", "es");
      aEn.className = aEn.className.replace(" set", "");
      aEs.className += " set";
      setCookie("lang", "es", 365);
    } else {
      trans = language.eng;
      document.getElementsByTagName("html")[0].setAttribute("lang", "en");
      aEs.className = aEn.className.replace(" set", "");
      aEn.className += " set";
      setCookie("lang", "en", 365);
    }
    translate = true;
  } else if (getCookie("lang") != "") {
    if (getCookie("lang").includes("es")) {
      trans = language.es;
      document.getElementsByTagName("html")[0].setAttribute("lang", "es");
      var langs = langDropdown.querySelectorAll("a");
      langs[0].className = langs[0].className.replace(" set", "");
      langs[1].className += " set";
      translate = true;
      setCookie("lang", "es", 365);
    } else {
      trans = language.eng;
    }
  } else if (navigator.language && navigator.language.includes("es")) {
    trans = language.es;
    document.getElementsByTagName("html")[0].setAttribute("lang", "es");
    translate = true;
    aEn.className = aEn.className.replace(" set", "");
    aEs.className += " set";
  } else {
    trans = language.eng;
  }
  if (translate) {
    menuLang.textContent = trans.idiom;
    modalMessage.textContent = trans.messageLogout;
    loginInst.textContent = trans.initSession;
    btnPhoneLogin.textContent = trans.phone;
    btnEmailLogin.textContent = trans.email;
    etEmail.placeholder = trans.email;
    etPassword.placeholder = trans.password;
    emailOptions.querySelector("label").textContent = trans.remember;
    btnLogin.textContent = trans.login;
    emailOptions.querySelector("p").innerHTML = trans.disclaimer;
  }
}
